import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Social from "../components/social.js"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import Layout from "../components/LayoutAlt.js"


export default function HFSinglePage({ data }) {
   const hf = data.strapiHealthFitness
   
  return (
    <Layout>
      <div className="sm:w-3/4 sm:ml-1/8   sm:mt-10 xl:mt-20 xs:p-4 sm:p-0 mb-20">
      <section className="grid md:grid-cols-1 md:gap-4 lg:grid-cols-2 lg:gap-0">
          <img 
          srcSet={hf.imgix_path}
          src={hf.src_img} 
          className="w-full object-cover"
          alt="Author's Avatar"/>
          <div className="text-gray-700 lg:bg-gray-50 pt-12 xs:px-4 sm:px-10">
            <h1 className="font-playfairdisplay font-bold xs:text-2xl sm:text-4xl xl:text-5xl 2xl:text-7xl ">{hf.title}</h1>
            <div className="flex justify-left items-center space-x-2 mt-4">
              <img src={hf.author.avatar.formats.thumbnail.url} alt="Author's Avatar" className="xs:h-8 xs:w-8 lg:h-10 lg:w-10 rounded-full border-2"/>
              <p className="font-opensans font-bold xs:text-xs xl:text-sm">{hf.author.name},</p>
              <p className="font-opensans xs:text-xs xl:text-sm">{hf.date}.</p>
            </div>
            <div className="border-2 border-orange-dark xs:mt-8 sm:mx-32 2xl:mt-12 xl:mx-32 2xl:mx-48"></div>
          </div>
      </section>

             <Link to="/health_fitness">
                <section className="font-opensans  text-white  bg-orange-dark xs:mt-24 sm:mt-20 lg:mt-5 xl:mt-10 py-2 pl-4 
                  rounded-r-xl flex items-center">
                    <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer"/> 
                    <p className=" text-xs sm:text-base">/health_fitness/{hf.slug}</p>
                  </section>
            </Link>

          <section className="mt-14">
            <article className="articles font-opensans lg:text-lg text-gray-700">
              <ReactMarkdown children={hf.content} />
            </article>
          </section>

          <section className="mt-20">
               <Link to="/health_fitness">
                    <button className="font-opensans font-bold text-white bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm py-1 px-8">
                     Back
                    </button>
                    </Link>
               </section>

      </div>
      <Social />
    </Layout>
  )
}

export const query = graphql`
   query($slug: String!) {
    strapiHealthFitness( slug: {eq: $slug} ) {
      title
      slug
      date(formatString: "LL")
      id
      content
      author {
        name
        avatar {
          formats {
            thumbnail {
              url
            }
          }
        }
      }
      imgix_path
    src_img
    }
  }
`